.navbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-border);
  box-sizing: border-box;
  background-color: var(--color-bg-2);
  height: 100%;
}

.left {
  display: flex;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  width: 200px;
  min-width: 200px;
  padding-left: 20px;
  box-sizing: border-box;
}

.logo-name {
  color: var(--color-text-1);
  font-weight: 500;
  font-size: 20px;
  margin-left: 10px;
  font-family: 'PingFang SC';
}

.right {
  display: flex;
  list-style: none;
  padding-right: 20px;
  align-items: center;

  li {
    padding: 0 8px;
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: var(--color-text-1);
  }
}

.username {
  cursor: pointer;
}

.round {
  :global(.arco-input-inner-wrapper) {
    border-radius: 16px;
  }

  svg {
    font-size: 16px;
  }
}

.dropdown-icon {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: text-bottom;
}

.fixed-settings {
  position: fixed;
  top: 280px;
  right: 0;

  svg {
    font-size: 18px;
    vertical-align: -4px;
  }
}

.social-icon {
  width: 38px;
  height: 38px;
  padding: 0 4px;
}

.navLinks {
  a, .navLink {
    text-decoration: none;
    color: black;
    text-transform: uppercase;
    margin-right: 30px;
    padding-bottom: 2px;
    font-weight: 600;
    font-size: 14px;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease-in-out;
    word-break: keep-all;
    line-break: strict;
    white-space: break-spaces;
    display: inline-block;

    &:hover {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }

    &.active {
      border-bottom: 1px solid black;
    }
  }
}

.drawer {
  position: relative;

  :global(.arco-drawer-scroll) {
    background: #1741ae;
  }

  :global(.arco-drawer-header) {
    border-bottom: 0;
  }

  :global(.arco-drawer-close-icon) {
    font-size: 20px;
    line-height: 46px;
    height: 46px;
    width: 38px;
    background: #1741ae !important;
    color: #fff !important;

    &::before {
      background: #1741ae !important;
    }
  }

  .navLink {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    display: block;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    padding: 16px 0;

    &.active {
      text-decoration: underline;
    }
  }

  .social-icons {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    padding: 16px 0;

    a.social-icon {
      display: inline-block;
      padding: 16px 0;
      filter: invert(100%);
      font-size: 28px;
    }
  }
}
