@nav-size-height: 80px;
@layout-max-width: 1165px;

@media print {
  :global(.noprint) {
    display: none;
  }
}

.layout {
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-2);
}

:global(body) {
  background-color: var(--color-bg-2);
}

.layout-navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: @nav-size-height;
  z-index: 100;
  background-color: var(--color-bg-2);
  //border-bottom: 1px solid var(--color-border);

  & > div {
    max-width: @layout-max-width;
    width: 100%;
    margin: 0 auto;
    border-bottom: 0;
  }

  &-hidden {
    height: 0;
  }
}

.layout-sider {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 12px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    background-clip: padding-box;
    border-radius: 7px;
    background-color: var(--color-text-4);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-text-3);
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 100%;
    background-color: var(--color-border);
  }

  > :global(.arco-layout-sider-children) {
    overflow-y: hidden;
  }

  .collapse-btn {
    height: 24px;
    width: 24px;
    background-color: var(--color-fill-1);
    color: var(--color-text-3);
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    // 位置
    position: absolute;
    bottom: 12px;
    right: 12px;

    &:hover {
      background-color: var(--color-fill-3);
    }
  }
}

.menu-wrapper {
  overflow: auto;
  height: 100%;

  :global(.arco-menu-item-inner > a::after),
  :global(.arco-menu-item > a::after) {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  :global(.arco-menu-inline-header) {
    font-weight: 500;
  }
}

.icon {
  font-size: 18px;
  vertical-align: text-bottom;
}

.icon-empty {
  width: 12px;
  height: 18px;
  display: inline-block;
}

.layout-content {
  //background-color: var(--color-fill-2);
  background-color: var(--color-bg-2);
  //min-width: @layout-max-width;
  width: 100%;
  min-height: 100vh;
  transition: padding-left 0.2s;
  box-sizing: border-box;
}

.layout-content-wrapper {
  //padding: 16px 20px 0;
  max-width: @layout-max-width;
  width: 100%;
  margin: 0 auto;
}

.mainContainer {
  max-width: @layout-max-width;
  padding: 0 20px;
  width: calc(100% - 40px);
  margin: 0 auto;
}

.layout-breadcrumb {
  margin-bottom: 16px;
}

.spin {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: calc(100vh - @nav-size-height);
}

:global(.arco-input:hover), :global(.arco-textarea:hover) {
  background-color: var(--color-fill-1);
}

:global(.clmd-button) {
  padding: 0 24px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  min-width: 220px;
  line-height: 70px;
  height: 70px;
  border-radius: 6px;

  &:global(.clmd-button-white) {
    color: black !important;
    background: white !important;
  }

  &:global(.clmd-button-round) {
    border-radius: 50px;
  }
}

:global(.clmd-button-small) {
  padding: 0 16px;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  min-width: 90px;
  height: 50px;
  border-radius: 6px;

  &:global(.clmd-button-white) {
    color: black !important;
    background: white !important;
  }

  &:global(.clmd-button-blue) {
    color: white !important;
    background: rgb(23, 65, 174) !important;

    &:hover, &:focus {
      background: #4566be !important;
    }
  }

  &:global(.clmd-button-round) {
    border-radius: 50px;
  }
}

.main-h1 {
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 50px;
  margin-bottom: 28px
}

.main-h2 {
  font-size: 37px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 50px;
  margin-bottom: 28px
}

.main-h3 {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 20px;
}

.main-h4 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 20px;
}

.main-h5 {
  font-size: 20px;
  font-weight: 400;
}

.main-big-text {
  font-size: 22px;
}

.main-desc {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 38.1833px;
  margin-bottom: 28px;
}

.main-limit-760 {
  max-width: 800px;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 0 20px;
}

.main-desc-2 {
  line-height: 28px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 28px;
  margin-bottom: 28px;
}

.color-blue {
  color: rgb(23, 65, 174);
}

.main-table {
  :global(.arco-table-th), :global(.arco-table-td) {
    font-size: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .center-on-mobile {
    text-align: center;
  }

  .hide-on-mobile {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }

  .margin-120-top-on-desktop {
    margin-top: 120px;
  }
}
