.footer {
  text-align: center;

  background: #1741ae;
  color: white;
  padding: 100px 0;

  a {
    color: white;
    text-decoration: none;
  }

  .footer_row {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
  }

  .footer__panel {
    padding: 40px 0;

    .footer__title {
      text-transform: uppercase;
      margin: 1rem 0;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 2px;
    }

    .footer__content {
      font-size: 16px;
      line-height: 35px;
      margin-bottom: 1rem;
      font-weight: 700;
    }
  }
}

